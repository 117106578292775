<template>
  <div
    class="sub_top"
    :class="{
      img1: menu_num === 1,
      img2: menu_num === 2,
      img3: menu_num === 3,
      img4: menu_num === 4,
      img5: menu_num === 5,
      img6: menu_num === 6,
    }"
  >
    <div class="wrap">
      <h1
        class="align-center"
        v-if="
          name !== 'Login' &&
          name !== 'Find_Id_Pwd' &&
          name !== 'ConfirmPassword' &&
          name !== 'Terms' &&
          name !== 'Privacy'
        "
      >
        {{ subtitle }}
      </h1>
      <h1 class="align-center" v-else>{{ subtitle }}</h1>
      <div class="nav-wrapper">
        <nav style="--bs-breadcrumb-divider: ''" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a @click="forceRerender('/')" class="more"><a>Home</a></a>
            </li>
            <li class="breadcrumb-item">
              <a
                class="more"
                @click="
                  forceRerender(
                    $route.matched[0].path && $route.matched[0].path
                  )
                "
                ><a>{{ step1 }}</a></a
              >
            </li>
            <li class="breadcrumb-item" v-if="step2">
              <a
                class="more"
                @click="
                  forceRerender(
                    $route.matched[1].meta.location
                      ? $route.matched[1].meta.location
                      : $route.matched[2].meta.location &&
                          $route.matched[2].meta.location
                  )
                "
                ><a>{{ step2 }}</a></a
              >
            </li>
            <li class="breadcrumb-item" aria-current="page" v-if="step3">
              <a
                class="more"
                @click="
                  forceRerender(
                    $route.matched[2].path && $route.matched[2].path
                  )
                "
                ><a>{{ step3 }}</a></a
              >
            </li>
          </ol>
        </nav>
      </div>
      <p class="mobile_title">{{ subtitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Location",
  methods: {
    forceRerender: function (val) {
      this.$store.commit("setSearchData", {});
      this.$store.commit("forceRerender");
      this.$router.push(val).catch(() => {});
    },
  },
  computed: {
    subtitle: function () {
      return this.$route.meta.subtitle ? this.$route.meta.subtitle : "";
    },
    step1: function () {
      return this.$route.meta.step ? this.$route.meta.step[0] : "";
    },
    step2: function () {
      return this.$route.meta.step ? this.$route.meta.step[1] : false;
    },
    step3: function () {
      return this.$route.meta.step ? this.$route.meta.step[2] : false;
    },
    name: function () {
      return this.$route.name ? this.$route.name : "";
    },
    m_subtitle: function () {
      return this.$route.meta.m_subtitle ? this.$route.meta.m_subtitle : "";
    },
    menu_num: function () {
      return this.$route.meta.menu_num || "";
    },
    path: function () {
      console.log("matched[0]", this.$route.matched[0]);
      console.log("matched[1]", this.$route.matched[1]);
      console.log("matched[2]", this.$route.matched[2]);
    },
  },
};
</script>

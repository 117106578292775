<template>
  <div>
    <location />
    <div class="page wrap-1200">
      <article class="article-header row g-0">
        <div class="col-12">
          <h5>{{ noticeview.title }}</h5>
        </div>
        <div class="d-flex flex-column flex-sm-row">
          <div class="d-flex align-items-center">
            <span class="icon badge bg-purple" v-if="noticeview.type === '1'">
              {{ $t("title.support.notice") }}
            </span>
            <span class="icon badge bg-blue" v-if="noticeview.type === '2'">
              {{ $t("title.support.news") }}
            </span>
            <span class="text-capitalize text-black">{{
              noticeview.regname
            }}</span>
          </div>
          <div class="ms-sm-auto mt-2 mt-sm-0 pt-1 pt-sm-0">
            <span class="me-2 text-date">
              <i class="material-icons">date_range </i>

              {{
                noticeview.timezone_date &&
                noticeview.timezone_date.timezone_common | dateformat
              }}
            </span>
            <span>
              <i class="material-icons">visibility</i>
              {{ noticeview.cnt | comma }}
            </span>
          </div>
        </div>
      </article>

      <article class="article-attach" v-if="noticeview.filename">
        <a :href="noticeview.download_link" class="text-truncate">
          <i class="material-icons-outlined">file_download</i>
          <span>{{ noticeview.originname }}</span>
        </a>
      </article>

      <article class="article-body">
        <span v-html="noticeview.contents"></span>
      </article>

      <article class="article-footer">
        <div class="prev" :class="!next_post.notice_web_pk && 'no-data'">
          <router-link
            :to="{
              name: 'NoticeView',
              params: { id: next_post.notice_web_pk },
            }"
          >
            <i class="material-icons-outlined"> expand_less </i>
            <span>{{ next_post.title || $t("text.support.no_prev") }}</span>
          </router-link>
        </div>
        <div class="next" :class="!prev_post.notice_web_pk && 'no-data'">
          <router-link
            :to="{
              name: 'NoticeView',
              params: { id: prev_post.notice_web_pk },
            }"
          >
            <i class="material-icons-outlined"> expand_more </i>
            <span>{{ prev_post.title || $t("text.support.no_next") }}</span>
          </router-link>
        </div>
      </article>
      <div class="btn-bottom-wrapper">
        <b-button
          size="xl"
          variant="outline-primary"
          :aria-label="$t('button.common.list')"
          @click="
            $router.push({
              name: 'Notice',
              query: {
                searchtype: $route.query.searchtype,
                searchname: $route.query.searchname,
              },
            })
          "
        >
          {{ $t("button.common.list") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/support";
import "@/filter/common";

export default {
  name: "NoticeView",
  components: {
    location,
  },
  data() {
    return {
      param: {
        searchtype: this.$route.query.searchtype || 1,
        searchname: this.$route.query.searchname || "",
        noticeno: this.$route.params.id,
      },
      noticeview: {
        type: "",
        title: "",
        date: "",
        cnt: "",
        contents: "",
        prevno: "",
        prevtitle: "",
        nextno: "",
        nexttitle: "",
      },
      next_post: {},
      prev_post: {},
    };
  },
  methods: {
    get_noticeview() {
      ax.get_noticeview(this.param, (flag, data) => {
        if (flag) {
          this.noticeview = data.noticeview;
          this.next_post = data.next_post;
          this.prev_post = data.prev_post;
        } else {
          alert(data);
        }
      });
    },
  },
  created() {
    this.get_noticeview();
  },
  watch: {
    "$route.params": function () {
      this.param.noticeno = parseInt(this.$route.params.id);
      this.get_noticeview();
    },
  },
};
</script>
